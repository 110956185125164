//Components
import TasksCard from './components/TasksCard.vue'
import TaskDetails from './components/TaskDetails.vue'
import TasksContent from '@/common/TasksCard/TasksContent.vue'
import TaskParticipantsDialog from './components/TaskParticipantsDialog/TaskParticipantsDialog.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'TasksTab',
    components: {
        TasksCard,
        TaskDetails,
        TaskParticipantsDialog,
        TasksContent
    },

    props: {
        id: [Number, String]
    },

    created() {
        this.set_project_id(this.id)
        this.set_user_id(null) //set to null for project tasks
        this.get_project_tasks(null)
        this.get_milestones({ all: true, list: true })
    },
    computed: {
        ...mapGetters(['user']),
        ...mapGetters('taskCards', ['is_fetching']),
        ...mapGetters('project', ['project']),
        activeTask: {
            get() {
                return this.$store.getters['taskCards/activeTask']
            },
            set(val) {
                return this.$store.commit('taskCards/set_activeTask', val)
            }
        },
        paths() {
            return [{
                    text: 'Dashboard',
                    disabled: false,
                    route: { name: 'default-content' }
                },
                {
                    text: this.type,
                    disabled: false,
                    route: { path: `/dashboard/${this.type}/preview/${this.id}` }
                },
                { text: 'Tasks', disabled: true, route: null }
            ]
        }
    },
    beforeRouteUpdate(to, from, next) {
        if (to.params.task_id) {
            this.set_activeTaskById(to.params.task_id)
        }
        next()
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
    },
    provide() {
        const properties = {}
        Object.defineProperty(properties, 'loading', {
            get: () => this.loading
        })
        return { properties, bodyMaxHeight: 'auto' }
    },
    methods: {
        ...mapMutations('taskCards', [
            'set_project_id',
            'set_user_id',
            'set_activeTask',
            'set_activeTaskById'
        ]),
        ...mapActions('taskCards', [
            'get_project_tasks',
            'load_more_project_tasks',
            'get_milestones'
        ]),
        setActiveTask(task) {
            this.set_activeTask(task)
        }

        // fetchEntries(val) {
        //     this.searchResult = []
        //     request.get(`api/projects/${this.id}/tasks/search?keyword=${val}`)
        //         .then(({ data }) => {
        //             this.searchResult = data.data
        //         })
        //         .finally(() => (this.isFetching = false))
        // },
        // open_new_task_dialog() {
        //     this.$event.$emit('task-add')
        // },
        // open_new_request_dialog() {
        //     this.$event.$emit('request-add')
        // },
        // showTask(item) {
        //     this.$event.$emit('show-task-side-preview', item)
        // },
        // syncAssignment(ids) {
        //     request.post(`api/task/${this.active_task_id}/sync-assignments`, { ids: ids })
        //         .then(({ data }) => {
        //             this.replace_task(data)
        //             this.keyy++
        //             this.appSnackbar( 'Task assignments updated!');
        //         })
        //         .finally(() => this.participant_dialog = false)
        // }
    }
}